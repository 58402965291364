import React from "react";

const DinningPage = () => (
  <React.Fragment>
    <section className="fdb-block">
      <div className="container" style={{ marginTop: 100 }}>
        <div className="row align-items-center">
          <div className="col-12 col-md-6 mb-4 mb-md-0">
            <img alt="fluid" className="img-fluid" src="banner/dinning.jpg" />
          </div>
          <div className="col-12 col-md-6 col-lg-5 ml-md-auto text-left">
            <h1>Dining</h1>
            <p className="lead">
              <u className="headtext"> Q - ZEEN</u>
              <br />A Multicuisine Restaurant.with Live kitchen offers a wide
              range of Indian and International Cusine .
            </p>

            <p className="lead">
              <u className="headtext"> Tafree</u>
              <br />A Roof top open Restaurant & Bar with live kitchen &
              Swimming pool- a most happening place where you enjoy varieties of
              Kebabs and Chef Special dishes on live ,Sitting on top embarace a
              paranomic view of Raipur city.
            </p>
          </div>
        </div>
      </div>
    </section>
    <br />
    <br />
  </React.Fragment>
);

export default DinningPage;
