/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { swing, bounceInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { withRouter } from "react-router-dom";
import Rest from "../utils/Rest";
import Loader from "react-loader";
import Ratings from "../components/Ratings";
import DatePicker from "../components/DatePicker";
import queryString from "query-string";
import { ToastsStore } from "react-toasts";
import calculatePrice from "../utils/calculatePrice";
import createSearchQuery from "../utils/createSearchQuery";

const styles = {
  swing: {
    animation: "x 1s",
    animationName: Radium.keyframes(swing, "swing"),
  },
  bounceInUp: {
    animation: "x 1s",
    animationName: Radium.keyframes(bounceInUp, "bounceInUp"),
  },
};
const RoomDetailsPage = (props) => {
  const { adult, child } = queryString.parse(props.location.search);
  const [adultState, setAdultState] = useState(adult ? adult : 1);
  const [childState, setChildState] = useState(child ? child : 0);
  const [isLoading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [shake, setShake] = useState(true);
  const [room, setRoom] = useState(null);
  const { roomId } = props.match.params;
  const { location, history } = props;
  useEffect(() => {
    const fetchData = async () => {
      const result = await Rest.get(`/room/${roomId}`);
      setRoom(result.data.payload);
      setLoading(true);
    };
    fetchData();
  }, [roomId]);
  if (room === null) {
    return <Loader loaded={isLoading}></Loader>;
  }

  const shakeIt = () => {
    setShake(false);
    setTimeout(() => setShake(true), 200);
  };

  const bookNow = async () => {
    setSubmitting(true);
    const { checkIn, checkOut, child = 0, adult = 1 } = queryString.parse(
      props.location.search
    );
    const { roomId } = props.match.params;

    const people = parseInt(adult) + parseInt(child);

    if (people > parseInt(room.capacity)) {
      ToastsStore.error("Room Capacity is " + room.capacity);
      setSubmitting(false);
      return;
    }

    if (typeof checkIn === "undefined") {
      shakeIt();
      ToastsStore.warning(" CheckIn & CheckOut time ?");
      setSubmitting(false);
      return;
    }
    if (typeof checkOut === "undefined") {
      shakeIt();
      ToastsStore.warning(" CheckIn & CheckOut time ?");
      setSubmitting(false);
      return;
    }
    try {
      const result = await Rest.post("/booking/roomavailability", {
        roomId,
        checkIn,
        checkOut,
      });
      const { data } = result;
      if (data.status === 200) {
        if (data.payload.availability > 0) {
          ToastsStore.success("Confirm Your Booking with instant payment");
          // lets start booking
          setSubmitting(false);
          history.push(`/checkout/${roomId}${location.search}`);
        } else {
          // no room available
          ToastsStore.error("No room Available");
          setSubmitting(false);
        }
      } else {
        setSubmitting(false);
      }
    } catch (error) {
      setSubmitting(false);
      ToastsStore.error("Something went Wrong!");
    }
  };

  const selectChange = (e) => {
    const { checkIn, checkOut, adult = 1, child = 0 } = queryString.parse(
      props.location.search
    );
    const { roomId } = props.match.params;
    const value = e.target.value;
    const name = e.target.name;
    if (name === "adult") {
      const people = parseInt(value) + parseInt(child);
      if (people > parseInt(room.capacity)) {
        ToastsStore.error("Room Capacity is " + room.capacity);
      }
      setAdultState(value);
      history.push(
        `/room/${roomId}${createSearchQuery(checkIn, checkOut, value, child)}`
      );
    } else {
      const people = parseInt(adult) + parseInt(value);
      if (people > parseInt(room.capacity)) {
        ToastsStore.error("Room Capacity is " + room.capacity);
      }
      setChildState(value);
      history.push(
        `/room/${roomId}${createSearchQuery(checkIn, checkOut, adult, value)}`
      );
    }
  };
  const { price, isOffer, offerDescription } = calculatePrice(
    room,
    adult,
    child
  );

  return (
    <React.Fragment>
      <StyleRoot>
        <div
          className="container shadow"
          style={{
            marginTop: 80,
            marginBottom: 20,
            padding: 0,
            border: "5px solid white",
          }}
        >
          <div
            style={{ position: "fixed", top: 50, left: 50, color: "#fff" }}
            onClick={() => props.history.goBack()}
          >
            <i
              style={{ fontSize: 25 }}
              className="fa fa-arrow-left"
              aria-hidden="true"
            ></i>
          </div>
          {/* room details image  */}
          <section>
            <div className="container-fluid">
              <div
                className="row justify-content-center align-items-center "
                style={{
                  backgroundImage: `url(${room.bannerImage})`,
                  height: 400,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {/* <img src={room.bannerImage} className="img-fluid roomdetailsimg" alt="room_pic" style={{ height: 380 }} /> */}
              </div>
            </div>
          </section>

          {/* room details image end */}

          {/* room details */}

          <section style={{ ...styles.bounceInUp }}>
            <div className="bg-light roomdetails ">
              <div className="container">
                {shake ? (
                  <DatePicker style={{ color: "black" }} />
                ) : (
                  <div style={{ height: 58 }} />
                )}
                <div
                  className="pl-3 pr-3"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <p
                    className=" text-dark "
                    style={{
                      marginBottom: "0px",
                      letterSpacing: "1px",
                    }}
                  >
                    <Ratings rating={room.rating ? room.rating : 3} />
                    <span style={{ fontSize: 24 }}> {room.name}</span>
                    <br />
                    <span className="font-weight-bold">₹ {price} / night</span>
                    {isOffer ? (
                      <strike
                        style={{
                          color: "#A02B2D",

                          fontSize: 14,
                          letterSpacing: 0.5,
                        }}
                        className="font-weight-bold"
                      >
                        {offerDescription}
                      </strike>
                    ) : null}
                  </p>

                  <button
                    disabled={submitting}
                    onClick={() => bookNow()}
                    style={{ border: "1px solid #fff" }}
                    className="btn btn-round shadow btn-lg"
                  >
                    {submitting ? "Booking...." : "Book Now"}
                  </button>
                </div>

                <div className="row mt-3">
                  <div className="col col-sm-12 col-lg-4">
                    <p className="lead text-dark font-weight-bold pl-3">
                      Amenities
                    </p>
                    <ul className="d-flex flex-wrap justify-content-between text-dark font-weight-bold">
                      <span>
                        <i
                          class="fa fa-wifi px-2 py-2 rounded-circle border"
                          aria-hidden="true"
                        ></i>
                        <br />
                        <span className="aminitext"> Internet</span>
                      </span>

                      <span>
                        <i
                          class="fa fa-cutlery px-2 py-2 rounded-circle border"
                          aria-hidden="true"
                        ></i>
                        <br />
                        <span className="aminitext">Food</span>
                      </span>

                      <span>
                        <i
                          class="fa fa-bed px-2 py-2 rounded-circle border"
                          aria-hidden="true"
                        ></i>
                        <br />
                        <span className="aminitext">Bed</span>
                      </span>

                      <span>
                        <i
                          class="fa fa-shower px-2 py-2 rounded-circle border"
                          aria-hidden="true"
                        ></i>
                        <br />
                        <span className="aminitext">Shower</span>
                      </span>
                    </ul>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginTop: 20,
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Adults</label>
                    <select
                      value={adultState}
                      name="adult"
                      onChange={(e) => selectChange(e)}
                      style={{ width: 100 }}
                      className="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Children</label>
                    <select
                      value={childState}
                      name="child"
                      onChange={(e) => selectChange(e)}
                      style={{ width: 100 }}
                      className="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </section>
          {/* room details end */}
        </div>
      </StyleRoot>
    </React.Fragment>
  );
};

export default withRouter(RoomDetailsPage);
