import React, { Component } from "react";
import { swing, bounceIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import InfiniteCalendar, { withRange, Calendar } from "react-infinite-calendar";
import Modal from "react-modal";
import moment from "moment";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import createSearchQuery from "../utils/createSearchQuery";

const CalendarWithRange = withRange(Calendar);

const styles = {
  swing: {
    animation: "x 1s",
    animationName: Radium.keyframes(swing, "swing"),
  },
  bounceIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(bounceIn, "bounceIn"),
  },
  pickerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: 300,
    color: "#fff",
    borderBottom: "1px solid transparent",
    fontSize: "1.5rem",
    fontWeight: 700,
    padding: 10,
    borderRadius: 5,
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.8)",
  },
};
Modal.setAppElement("#root");
export class DatePicker extends Component {
  constructor(props) {
    super(props);

    const { checkIn, checkOut } = queryString.parse(props.location.search);
    this.state = {
      checkIn: checkIn ? moment(checkIn, "DD/MM/YYYY").toDate() : new Date(),
      checkOut: checkOut ? moment(checkOut, "DD/MM/YYYY").toDate() : null,
      date: false,
    };
  }
  render() {
    const { checkIn, checkOut, date } = this.state;
    const { style } = this.props;
    return (
      <center>
        <StyleRoot>
          <div
            style={{ ...styles.pickerContainer, ...style }}
            className="shadow-lg "
          >
            <div
              className="d-flex align-items-center"
              style={{
                cursor: "pointer",
                borderBottom: "1px solid #fff",
                fontSize: 20,
                color: "#fff",
              }}
              onClick={() => this.setState({ date: true })}
            >
              <span>
                {" "}
                <img
                  src="/calendar.png"
                  className="img-fluid"
                  alt="calendar"
                  style={{ width: 18 }}
                />
              </span>{" "}
              &nbsp;
              {moment(checkIn, "DD/MM/YYYY").format("DD/MM/YYYY") ===
              "Invalid date"
                ? "Check In"
                : moment(checkIn, "DD/MM/YYYY").format("DD/MM/YYYY")}
            </div>
            <div style={{ color: "#fff" }}>|</div>
            <div
              className="d-flex align-items-center"
              style={{
                cursor: "pointer",
                borderBottom: "1px solid #fff",
                fontSize: 20,

                color: "#fff",
              }}
              onClick={() => this.setState({ date: true })}
            >
              <span>
                {" "}
                <img
                  src="/calendar.png"
                  className="img-fluid"
                  alt="calendar"
                  style={{ width: 18 }}
                />
              </span>{" "}
              &nbsp;
              {moment(checkOut, "DD/MM/YYYY").format("DD/MM/YYYY") ===
              "Invalid date"
                ? "Check Out"
                : moment(checkOut, "DD/MM/YYYY").format("DD/MM/YYYY")}
            </div>
          </div>
        </StyleRoot>
        <Modal
          isOpen={date}
          onRequestClose={() => this.setState({ date: false })}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <StyleRoot>
            <div style={styles.bounceIn}>
              <InfiniteCalendar
                minDate={new Date()}
                height={300}
                Component={CalendarWithRange}
                onSelect={(date) => {
                  this.setState({
                    checkIn: date.start,
                    checkOut: date.end,
                  });
                }}
                selected={{
                  start: checkIn,
                  end: checkOut,
                }}
                locale={{
                  headerFormat: "MMM Do",
                }}
              />
              <center>
                <button
                  disabled={checkOut === null}
                  onClick={() => {
                    const { history, location } = this.props;
                    const { adult, child } = queryString.parse(location.search);
                    this.setState({ date: false });
                    const freshCheckIn = moment(checkIn, "DD/MM/YYYY").format(
                      "DD/MM/YYYY"
                    );
                    const freshCheckOut = moment(checkOut, "DD/MM/YYYY").format(
                      "DD/MM/YYYY"
                    );
                    const search = createSearchQuery(
                      freshCheckIn,
                      freshCheckOut,
                      adult,
                      child
                    );
                    if (location.pathname === "/") {
                      history.push(`/rooms${search}`);
                    } else {
                      history.push(`${location.pathname}${search}`);
                    }
                  }}
                  style={{
                    border: "1px solid #fff",
                    color: "#fff",
                    background: "#a02b2d",
                  }}
                  className=" btn shadow"
                >
                  Check Availability
                </button>
                <button
                  style={{
                    border: "1px solid #fff",
                    color: "#fff",
                    marginLeft: 10,
                    background: "#a02b2d",
                  }}
                  onClick={() => this.setState({ date: false })}
                  className=" btn shadow "
                >
                  Close
                </button>
              </center>
            </div>
          </StyleRoot>
        </Modal>
      </center>
    );
  }
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #a02b2d",
    borderRadius: 10,
  },
  overlay: {
    // backgroundColor: "transparent",
  },
};

export default withRouter(DatePicker);
