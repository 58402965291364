import React from 'react'
// import PDFViewer from 'pdf-viewer-reactjs'
import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';


const CassanovaMenu = () => {
    return (
<React.Fragment>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
    {/* <div style={{ height: '750px',width: '100%', border: '1px solid red' ,margin: '0 auto'}}> */}
    <div className="container">
    <Viewer fileUrl="https://res.cloudinary.com/relive-cloud/image/upload/v1603449960/vennington/menu/cassanova-menu.pdf" 
      />
      </div>
    {/* </div> */}
</Worker>

</React.Fragment>
 

    )
}

export default CassanovaMenu;



