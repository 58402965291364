/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import Rest from "../utils/Rest";
import { withRouter } from "react-router-dom";
import ReactLoader from "react-loader";
import queryString from "query-string";
import calculatePrice from "../utils/calculatePrice";
import CheckOutButton from "../components/CheckOutButton";

const CheckOutPage = (props) => {
  const { checkIn, checkOut, adult = 1, child = 0 } = queryString.parse(
    props.location.search
  );
  const people = parseInt(adult) + parseInt(child);
  const [isLoading, setLoading] = useState(false);
  const [extraMattress, setExtraMattress] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [room, setRoom] = useState(null);

  const { roomId } = props.match.params;

  useEffect(() => {
    const fetchData = async () => {
      const result = await Rest.get(`/room/${roomId}`);
      setRoom(result.data.payload);
      setLoading(true);
    };
    fetchData();
  }, [roomId]);

  const BookNow = (e) => {
    e.preventDefault();
  };
  if (room === null) {
    return <ReactLoader loaded={isLoading}></ReactLoader>;
  }
  const {
    price,
    isOffer,
    offerDescription,
    originalPrice,
    discountPrice,
  } = calculatePrice(room, adult, child, extraMattress);

  return (
    <React.Fragment>
      <div className="container" style={{ marginTop: 80, height: "100vh" }}>
        <div className="py-1 text-center">
          <h2 style={{ color: "#A02B2D" }}>Payment</h2>
        </div>
        <div className="row">
          <div className="col-md-4 order-md-2 mb-4">
            <ul className="list-group mb-3">
              <li className="list-group-item d-flex justify-content-between align-items-center lh-condensed">
                <div>
                  <h6 className="my-0">{room.name}</h6>
                  {/* <small className="text-muted">1</small> */}
                </div>
                <span className="text-muted">
                  <img
                    alt="room-image"
                    src={room.images[0]}
                    style={{ width: 70, height: 70 }}
                  />
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between  lh-condensed">
                <div>
                  <h6 className="my-0">Price</h6>
                  <small className="text-muted">Adult * {adult}, </small>
                  <small className="text-muted">Child * {child} </small>
                </div>
                <span className="text-muted"> ₹ {originalPrice}</span>
              </li>
              {isOffer ? (
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="my-0">Offer </h6>
                    <small className="text-muted">{`${offerDescription}`}</small>
                  </div>
                  <span className="text-muted"> - ₹ {discountPrice}</span>
                </li>
              ) : null}
              {people > 1 ? (
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="my-0"> You need extra mattress </h6>
                    <small className="text-muted">
                      {" "}
                      + ₹ {room.extraMattressForChildren}{" "}
                    </small>
                  </div>
                  <span className="text-muted">
                    <input
                      type="checkbox"
                      value={extraMattress}
                      onChange={() => setExtraMattress(!extraMattress)}
                      class="form-check-input"
                      id="exampleCheck1"
                    />
                  </span>
                </li>
              ) : null}

              <li className="list-group-item d-flex justify-content-between">
                <span>Total</span>
                <strong> ₹ {price}</strong>
              </li>
            </ul>
          </div>
          <div className="col-md-8 order-md-1">
            <h4 className="mb-3" style={{ color: "#A02B2D" }}>
              Personal Details
            </h4>
            <form className="needs-validation" noValidate onSubmit={BookNow}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="firstName">
                    Booking Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Booking Name"
                    defaultValue
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="lastName">
                    Mobile number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="lastName"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    placeholder="Mobile Number"
                    defaultValue
                    required
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="lastName">Email (Optional)</label>
                  <input
                    fluid
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email Address"
                    defaultValue
                    required
                  />
                </div>
              </div>

              <CheckOutButton
                name={name}
                mobile={mobile}
                email={email}
                amount={price}
                room={room}
                child={child}
                adult={adult}
                extraMattress={extraMattress}
                checkIn={checkIn}
                checkOut={checkOut}
              />
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CheckOutPage);
