const calculatePrice = (room, adultArg = 1, childArg = 0, extraMattress) => {
  //** price calculation **/
  const adult = parseInt(adultArg);
  const child = parseInt(childArg);

  let price = room.price;

  if (adult > 1) {
    price = price + room.extraAdultPrice * (adult - 1);
  }
  if (child > 0) {
    price = price + room.extraChildPrice * child;
  }

  const originalPrice = price;
  let discountPrice = 0;
  //** offer calculation **/
  let isOffer = room.isOffer;
  let offer = room.offer;
  let offerType = room.offerType;
  let offerDescription = room.offerDescription;
  if (isOffer) {
    if (offerType === "cash") {
      console.log(offerType);
      price = price - offer;
      discountPrice = offer;
      offer = room.offer;
      offerType = room.offerType;
      offerDescription = room.offerDescription;
    } else {
      const offerPrice = (parseInt(price) * parseInt(offer)) / 100;
      discountPrice = parseFloat(offerPrice).toFixed(0);
      price = price - parseFloat(offerPrice).toFixed(0);
      offer = room.offer;
      offerType = room.offerType;
      offerDescription = room.offerDescription;
    }
  } else {
    discountPrice = 0;
    offer = 0;
    offerType = room.offerType;
    offerDescription = room.offerDescription;
  }

  if (extraMattress) {
    price = price + room.extraMattressForChildren;
  }

  return { price, isOffer, offer, offerType, offerDescription, originalPrice, discountPrice };
};

export default calculatePrice;
