/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import RoomDetails from "../components/RoomDetails";

const styles = {
  fadeIn: {
    animation: "x 4s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
};

const Rooms = ({ room, location }) => (
  <React.Fragment>
    <StyleRoot>
      <section className="fdb-block mt-5 pt-5 mb-5 pb-5" id="rooms">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <h1 className=" headtext" style={styles.fadeIn}>
                Rooms & Suites
              </h1>
              <hr className="bordermin" />
            </div>
          </div>

          <RoomDetails />
        </div>
      </section>
    </StyleRoot>
  </React.Fragment>
);

export default Rooms;
