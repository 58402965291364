import React, { useState, useEffect } from "react";
import ReactLoader from "react-loader";
import Rest from "../utils/Rest";
import Product from "../components/Product";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";

const AvailableRoomPage = (props) => {
  const { checkIn, checkOut } = queryString.parse(props.location.search);
  const [isLoading, setLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const result = await Rest.post("/booking/availability", {
        checkIn: moment(checkIn, "DD/MM/YYYY").format("YYYY/MM/DD"),
        checkOut: moment(checkOut, "DD/MM/YYYY").format("YYYY/MM/DD"),
      });
      setRooms(result.data.payload);
      setLoading(true);
    };
    fetchData();
  }, [checkIn, checkOut]);
  return (
    <section>
      <div className="container" style={{ marginTop: 100 }}>
        <center>
          <h3>Available Rooms</h3>
        </center>
        <ReactLoader loaded={isLoading}>
          {rooms.map((room, i) => {
            if (room.availability === 0) return null;

            return <Product key={i} room={room} />;
          })}
        </ReactLoader>
      </div>
    </section>
  );
};

export default withRouter(AvailableRoomPage);
