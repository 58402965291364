import React from "react";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import RoomDetailsPage from "./pages/RoomDetailsPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeLayout from "./layouts/HomeLayout";
import CheckOutPage from "./pages/CheckOutPage";
import AvailableRoomPage from "./pages/AvailableRoomPage";
import BookingDetailsPage from "./pages/BookingDetailsPage";
import Clubs from "./pages/Clubs";
import Banquet from "./pages/Banquet";
import FacilitiesPage from "./pages/FacilitiesPage";
import ServicesPage from "./pages/ServicesPage";
import Allrooms from "./sections/Allrooms";
import Contact from "./sections/Contact";
import AboutPage from "./pages/AboutPage";
import DinningPage from "./pages/DinningPage";
import QzeenMenuPage from "./pages/QzeenMenuPage";
import TafreeMenuPage from "./pages/TafreeMenuPage";
import BarMenupage from "./pages/BarMenupage";
import CassanovaMenuPage from "./pages/CassanovaMenuPage";

function App() {
  return (
    <Router>
      <HomeLayout>
        <Switch>
          <Route exact path="/" component={LandingPage}></Route>
          <Route exact path="/rooms" component={AvailableRoomPage}></Route>
          <Route exact path="/room/:roomId" component={RoomDetailsPage}></Route>
          <Route
            exact
            path="/checkout/:roomId"
            component={CheckOutPage}
          ></Route>
          <Route
            exact
            path="/booking/:bookingId"
            component={BookingDetailsPage}
          ></Route>
          <Route exact path="/clubs" component={Clubs}></Route>
          <Route exact path="/banquet" component={Banquet}></Route>
          <Route exact path="/facilities" component={FacilitiesPage}></Route>
          <Route exact path="/allrooms" component={Allrooms}></Route>
          <Route exact path="/services" component={ServicesPage}></Route>
          <Route exact path="/contact" component={Contact}></Route>
          <Route exact path="/about" component={AboutPage}></Route>
          <Route exact path="/dinning" component={DinningPage}></Route>
          <Route exact path="/qzeen-menu" component={QzeenMenuPage}></Route>
          <Route exact path="/tafree-menu" component={TafreeMenuPage}></Route>
          <Route exact path="/bar-menu" component={BarMenupage}></Route>
          <Route exact path="/cassanova-menu" component={CassanovaMenuPage}></Route>

          <Route
            exact
            path="/restaurants"
            component={BookingDetailsPage}
          ></Route>

          <Route component={NoRouteComponent}></Route>
        </Switch>
      </HomeLayout>
    </Router>
  );
}

const NoRouteComponent = (props) => {
  console.log(props);
  return (
    <h3 style={{ marginTop: 100, color: "red" }}>
      Not Valid Path {props.location.pathname}
    </h3>
  );
};

export default App;
