/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const Contact = () => (
  <React.Fragment>
    <section className="fdb-block" style={{ marginTop: 100 }}>
      <div className="container-fluid p-0 pb-md-5">
        <iframe
          className="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14873.531851863068!2d81.6375102!3d21.2563033!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6c6d235b7ba9fe03!2sHotel%20Vennington%20Court!5e0!3m2!1sen!2sin!4v1591694405754!5m2!1sen!2sin"
          width="100%"
          height={300}
          frameBorder={0}
          style={{ border: 0 }}
          allowFullScreen
        />
      </div>
      <div className="container">
        <div className="row mt-5 mx-auto formw">
          <div className="col-12 col-md-12">
            <h2>Contact Us</h2>
            <p className="lead">Jail Road, Fafadih Raipur, C.G</p>

            <p className="h5">
              <strong>Email:</strong> reservation@venningtoncourt.com
            </p>
            <p className="h5">
              <strong>Phone:</strong> 07714333333
            </p>
          </div>

          {/* <div className="col-12 col-md-6 ml-auto pt-5 pt-md-0 mycontent-left  ">
            <form>
              <h2 className="pb-2">Inquiry Now</h2>

              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full name"
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Mobile number"
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col">
                  <textarea
                    className="form-control"
                    name="message"
                    rows={3}
                    placeholder="How can we help?"
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <button type="submit" className="btn btn-primary">
                    Inquiry
                  </button>
                </div>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </section>
    <br />
    <br />
    <br />
  </React.Fragment>
);

export default Contact;
