/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

const BottomNavigation = () => (
  <React.Fragment>
    <div className="bottom-navigation">
      <div className="bottom-navigation-item bn-active">
        <a className="book_link_hover"
          href="https://g.page/Vennington?share"
          rel="nofollow noreferrer noopener"
          target="_blank"
        >
          <span>
            <i className="fa fa-map-marker" />{" "}
            <label className="bottom_navigator_label">
              Fafadih Chowk, Railway Station Road, Raipur, CG, 492001
            </label>
          </span>
          <label className="bottom_navigator_mobile_label">
            Get Directions
          </label>
        </a>
      </div>
      <div className="bottom-navigation-item bn-active pr-3">
        <a className="book_link_hover" href="tel:+91-7714333333">
          <span>
            <i className="fa fa-phone" />{" "}
            <label className="bottom_navigator_label">+91-07714333333</label>
          </span>
          <label className="bottom_navigator_mobile_label">Call Us</label>
        </a>
      </div>
      <div className="bottom-navigation-item bn-active">
        <a className="book_link_hover" href="mailto:reservation@venningtoncourt.com">
          <span>
            <i className="fa fa-envelope" />{" "}
            <label className="bottom_navigator_label">
              reservation@venningtoncourt.com
            </label>
          </span>
          <label className="bottom_navigator_mobile_label">Mail Us</label>
        </a>
      </div>
    </div>
  </React.Fragment>
);

export default BottomNavigation;
