import React from 'react'
import QzeenMenu from '../components/QzeenMenu'

const QzeenMenuPage = () => {
    return (
     <QzeenMenu/>
    )
}

export default QzeenMenuPage
