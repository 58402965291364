/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Services = () => (
  <React.Fragment>
    <section className="fdb-block" id="services">
      <div className="container pb-5">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h1 className="pt-5 mt-2 headtext">Services</h1>
            <hr className="borderminser" />
          </div>
        </div>
        <div className="row text-center mt-5">
          <div className="col-12 col-sm-4">
            <img
              alt="image"
              className="fdb-icon serviceicon"
              src="/reserve.png"
            />
            <h3 className="mt-3 text-dg">
              <strong>
                <a href="/services" className="link_hover">
                  Q - ZEEN
                </a>
              </strong>
            </h3>
            <p className="text-secondary">
              A Multicuisine Restaurant.with Live kitchen offers a wide range...
              <a href="/services/#qzeen" className="headtext link_hover">
                read more
              </a>
            </p>
          </div>
          <div className="col-12 col-sm-4 pt-4 pt-sm-0">
            <img
              alt="image"
              className="fdb-icon serviceicon"
              src="/person.png"
            />
            <h3 className="mt-3 text-dg">
              <strong>
                <a href="/services" className="link_hover">
                  Tafree
                </a>
              </strong>
            </h3>
            <p className="text-secondary">
              A Roof top open Restaurant & Bar with live kitchen & Swimming
              pool...
              <a href="services/#tafree" className="headtext link_hover">
                read more
              </a>
            </p>
          </div>
          <div className="col-12 col-sm-4 pt-4 pt-sm-0">
            <img alt="image" className="fdb-icon serviceicon" src="/dj.png" />
            <h3 className="mt-3 text-dg">
              <strong>
                <a href="/services" className="link_hover">
                  Club Casanova
                </a>
              </strong>
            </h3>
            <p className="text-secondary">
              Disco Theque.-Combination of a rustic & modern ambience with
              Loud...
              <a href="/services/#club" className="headtext link_hover">
                read more
              </a>
            </p>
          </div>

          <div className="col-12 col-sm-6 pt-4 pt-sm-0">
            <img
              alt="image"
              className="fdb-icon serviceicon"
              src="/banquet.png"
            />
            <h3 className="mt-3 text-dg">
              <strong>
                <a href="/services" className="link_hover">
                  PETALS & CHERISH
                </a>
              </strong>
            </h3>
            <p className="text-secondary">
              Two Banquet halls up to 500 gatherings a right ...
              <a href="/services/#petals" className="headtext link_hover">
                read more
              </a>
            </p>
          </div>

          <div className="col-12 col-sm-6 pt-4 pt-sm-0">
            <img alt="image" className="fdb-icon serviceicon" src="/deal.png" />
            <h3 className="mt-3 text-dg">
              <strong>
                <a href="/services" className="link_hover">
                  THE DEAL
                </a>
              </strong>
            </h3>
            <p className="text-secondary">
              State-of the -Art Hi tech Board room to meet all...
              <a href="/services/#deal" className="headtext link_hover">
                read more
              </a>
            </p>
          </div>
        </div>
        {/* <div className="row mt-5 justify-content-center">
          <div className="col-8">
            <img alt="image" className="img-fluid" src="./undraw.png" />
          </div>
        </div> */}
      </div>
    </section>
  </React.Fragment>
);

export default Services;
