/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Clubs = () => (
  <React.Fragment>
    <div className="container">
      <p className="text-center h1 headtext" style={{ marginTop: 140 }}>
        <u> Clubs</u>
      </p>

      <div className="row mt-4">
        <div className="col-md-6">
          <p className="lead text-secondary mobw ">
            Disco Theque.-Combination of a rustic & modern ambience with Loud
            music where our Youmgs break their Legs & moods.
          </p>
        </div>
        <div className="col-md-6 ">
          <div className="text-center">
            <button
              type="button"
              class="btn btn-lg btn-outline-danger  inquirybtn textwrap font-weight-bold mr-4"
            >
              <a href="tel:077143 33333">
                <i class="fa fa-phone " aria-hidden="true"></i>
              </a>
            </button>
          </div>
        </div>
      </div>

      <div className="row" style={{ marginTop: 30 }}>
        <div className="col-md-4 ">
          <img
            src="https://images.pexels.com/photos/274192/pexels-photo-274192.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="img-fluid img-thumbnail shadow clubsimg"
            alt="clubs"
          />
        </div>
        <div className="col-md-4">
          <img
            src="https://images.pexels.com/photos/341858/pexels-photo-341858.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="img-fluid img-thumbnail shadow clubsimg"
            alt="clubs"
          />
        </div>
        <div className="col-md-4">
          <img
            src="https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="img-fluid img-thumbnail shadow clubsimg"
            alt="clubs"
          />
        </div>
      </div>
      <div className="row" style={{ marginTop: 30 }}>
        <div className="col-md-4 ">
          <img
            src="https://images.pexels.com/photos/164745/pexels-photo-164745.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="img-fluid img-thumbnail shadow clubsimg"
            alt="clubs"
          />
        </div>
        <div className="col-md-4">
          <img
            src="https://images.pexels.com/photos/4784/alcohol-bar-party-cocktail.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="img-fluid img-thumbnail shadow clubsimg"
            alt="clubs"
          />
        </div>
        <div className="col-md-4">
          <img
            src="https://images.pexels.com/photos/382297/pexels-photo-382297.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="img-fluid img-thumbnail shadow clubsimg"
            alt="clubs"
          />
        </div>
      </div>
    </div>
    <br />
    <br />
  </React.Fragment>
);

export default Clubs;
