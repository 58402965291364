/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const FacilitiesPage = () => (
  <React.Fragment>
    <section className="fdb-block team-4">
      <div className="container" style={{ marginTop: 100 }}>
        <div className="row text-center justify-content-center">
          <div className="col-8">
            <h1 className="headtext">Facilities</h1>
            <hr className="bordermin" />
          </div>
        </div>
        <div className="row text-center mt-5">
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1493770348161-369560ae357d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <p className="mt-3">Lavish Buffet breakfast on the house</p>
          </div>
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1542296332-2e4473faf563?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <p className="mt-3">Airport / Railway Station Transfer</p>
          </div>
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1526411116157-21b2b172c6bc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <p className="mt-3">Speed Wi-Fi Connectivity</p>
          </div>
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1492138645880-160f6a5136fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <p className="mt-3">24 hours In Room Dinning</p>
          </div>

          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1558160074-4d7d8bdf4256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <p className="mt-3"> Tea & Coffee Maker</p>
          </div>
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1584433144697-205892243f4d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1488&q=80"
            />
            <p className="mt-3"> Electronic Safe</p>
          </div>
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/flagged/photo-1572609239482-d3a83f976aa0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80"
            />
            <p className="mt-3"> Mini Bar Cable TV/LED Television</p>
          </div>
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1522336284037-91f7da073525?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <p className="mt-3"> Hair Dryer, Shaving Mirror</p>
          </div>

          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1576678433413-202829a1ab98?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <p className="mt-3"> Cubical Shower</p>
          </div>
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1543489822-c49534f3271f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80"
            />
            <p className="mt-3"> Swimming Pool</p>
          </div>
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1573688150075-43af211536fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <p className="mt-3"> Centrally Aircondition</p>
          </div>
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1492138645880-160f6a5136fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <p className="mt-3"> Power back up</p>
          </div>

          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1541534741688-6078c6bfb5c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1349&q=80"
            />
            <p className="mt-3"> Fitness Center</p>
          </div>
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1584521764592-48f33fe7e088?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <p className="mt-3"> Doctor on Call</p>
          </div>
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1526626607369-f89fe1ed77a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <p className="mt-3"> Valley Parking</p>
          </div>
          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1537407684649-8a13dbf548f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <p className="mt-3"> Luggage Room</p>
          </div>

          <div className="col-3">
            <img
              alt="image"
              className="img-fluid rounded shadow-sm"
              src="https://images.unsplash.com/photo-1496745109441-36ea45fed379?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1349&q=80"
            />
            <p className="mt-3"> Guest safety & Hyegine</p>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default FacilitiesPage;
