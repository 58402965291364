import React from "react";
import { ToastsContainer, ToastsStore } from "react-toasts";
import NavBar from "../components/NavBar";
import BottomNavigation from "../components/BottomNavigation";
import Footer from "../components/Footer";

const HomeLayout = ({ children }) => (
  <React.Fragment>
    <NavBar />
    {children}
    <Footer />
    <BottomNavigation />
    {/* Toast */}
    <ToastsContainer store={ToastsStore} />
  </React.Fragment>
);

export default HomeLayout;


