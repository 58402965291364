import React from 'react'
import BarMenu from '../components/BarMenu'

const BarMenupage = () => {
    return (
        <div>
            <BarMenu/>
        </div>
    )
}

export default BarMenupage
