const createSearchQuery = (checkIn, checkOut, adult, child) => {
  let search = "?";
  if (checkIn) {
    search += `checkIn=${checkIn}&`;
  }
  if (checkOut) {
    search += `checkOut=${checkOut}&`;
  }
  if (adult) {
    search += `adult=${adult}&`;
  }
  if (child) {
    search += `child=${child}&`;
  }
  return search;
};

export default createSearchQuery;
