import React from "react";
const Ratings = ({ rating }) => {
  if (rating === 0) {
    return (
      <p style={{ margin: 0 }}>
        <span className="fa fa-star text-secondary"></span>
        <span className="fa fa-star text-secondary "></span>
        <span className="fa fa-star text-secondary "></span>
        <span className="fa fa-star text-secondary "></span>
        <span className="fa fa-star text-secondary "></span>
      </p>
    );
  }
  if (rating === 1) {
    return (
      <p style={{ margin: 0 }}>
        <span className="text-secondary">{rating}</span>&nbsp;
        <span className="fa fa-star text-warning"></span>
        <span className="fa fa-star text-secondary"></span>
        <span className="fa fa-star text-secondary"></span>
        <span className="fa fa-star text-secondary"></span>
        <span className="fa fa-star text-secondary"></span>
      </p>
    );
  }
  if (rating === 2) {
    return (
      <p style={{ margin: 0 }}>
        <span className="text-secondary">{rating}</span>&nbsp;
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-secondary"></span>
        <span className="fa fa-star text-secondary"></span>
        <span className="fa fa-star text-secondary"></span>
      </p>
    );
  }
  if (rating === 3) {
    return (
      <p style={{ margin: 0 }}>
        <span className="text-secondary">{rating}</span>&nbsp;
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-secondary"></span>
        <span className="fa fa-star text-secondary"></span>
      </p>
    );
  }
  if (rating === 4) {
    return (
      <p style={{ margin: 0 }}>
        <span className="text-secondary">{rating}</span>&nbsp;
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-secondary"></span>
      </p>
    );
  }
  if (rating === 5) {
    return (
      <p style={{ margin: 0 }}>
        <span className="text-secondary">{rating}</span>&nbsp;
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-orange"></span>
        <span className="fa fa-star text-orange"></span>
      </p>
    );
  }

  return (
    <p style={{ margin: 0 }}>
      <span className="text-secondary">3</span>&nbsp;
      <span className="fa fa-star text-orange"></span>
      <span className="fa fa-star text-orange"></span>
      <span className="fa fa-star text-orange"></span>
      <span className="fa fa-star"></span>
      <span className="fa fa-star"></span>
    </p>
  );
};

export default Ratings;
