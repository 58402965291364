import React from "react";
import HorizontalScroll from "./HorizontalScroll";
import Ratings from "./Ratings";
import calculatePrice from "../utils/calculatePrice";
import { Link, withRouter } from "react-router-dom";

const Product = ({ room, location }) => {
  const { price, isOffer, offerDescription } = calculatePrice(room);

  console.log(room.rating);
  return (
    <React.Fragment>
      <HorizontalScroll lists={room.images} room={room} />
      <div className="mx-auto d-block pb-3 w-55">
        <div className="d-flex justify-content-between align-items-center">
          <div className="mt-1">
            <Ratings rating={room.rating ? room.rating : 3} />
            <p
              className=" text-dark "
              style={{
                fontSize: 24,
                marginBottom: "0px",
                letterSpacing: "1px",
              }}
            >
              {room.name}
            </p>
            <p
              className="h6 font-weight-bold mt-1  text-dark"
              style={{ letterSpacing: "1px" }}
            >
              ₹ {price} / night{" "}
              {isOffer ? (
                <b
                  style={{
                    color: "#A02B2D",
                    fontWeight: 300,
                    fontSize: 14,
                    letterSpacing: 0.5,
                  }}
                  className="font-weight-bold"
                >
                  {`${offerDescription}`}
                </b>
              ) : (
                ""
              )}
            </p>
          </div>
          <div>
            <Link to={`/room/${room._id}${location.search}`} className="book_link_hover">
              <button className="btn btn-round shadow">Book Now</button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Product);
