/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";

// https://github.com/guard/listen/wiki/Increasing-the-amount-of-inotify-watchers#the-technical-details

// System limit for number of file watchers

const About = () => (
  <React.Fragment>
    <section className="fdb-block  pt-5 pb-5 mb-5 bg-light shadow-sm">
      <div className="container">
        <div className="row text-center align-items-center">
          <div className="col-8 col-md-4">
            <img
              alt="image"
              className="img-fluid shadow"
              src="about/pool.jpg"
            />
          </div>
          <div className="col-4 col-md-2">
            <div className="row">
              <div className="col-12">
                <img
                  alt="image"
                  className="img-fluid shadow"
                  src="about/room.jpg"
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <img
                  alt="image"
                  className="img-fluid shadow"
                  src="about/room1.jpg"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-5 ml-auto pt-5 pt-md-0">
            {/* <img alt="image" className="fdb-icon" src="./imgs/icons/github.svg" /> */}
            <h1 className="headtext">About</h1>
            <hr className="bordermin" />

            <p className="lead text-secondary">
              Conceptualizing, theme & motto based on (3 C) Comfort, Cuisine and
              Culture; Hotel Vennington Court is established in December 2016.
              Experience Luxury hospitality with us, our hotel is located at the
              finest and convenient place of Jail Road Raipur in Capital City...
              <Link to="/about" className="headtext link_hover">
                read more
              </Link>
            </p>

            <Link to="#rooms" className="book_link_hover pr-1">
              <button className="btn btn-round shadow">Rooms</button>
            </Link>
            <Link to="#facilities" className="book_link_hover pr-1">
              <button className="btn btn-round shadow">Facilities</button>
            </Link>
            <Link to="#services" className="book_link_hover pr-1">
              <button className="btn btn-round shadow">Services</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default About;
