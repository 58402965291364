import React from 'react'
import TafreeMenu from '../components/TafreeMenu'

const TafreeMenuPage = () => {
    return (
        
            <TafreeMenu/>
        
    )
}

export default TafreeMenuPage
