import React from "react";
import "react-infinite-calendar/styles.css";
import DatePicker from "../components/DatePicker";
import "./seesaw.css";
import Facilities from "./Facilities";
import Services from "./Services";
import About from "../sections/About";
import Rooms from "../sections/Rooms";
import { Slide } from "react-slideshow-image";

const slideImages = [
  "banner/hotelvennington.jpg",
  "banner/club.jpg",
  "banner/dinning.jpg",
  "banner/meeting.jpg",
  "banner/reception.jpg",
];

const properties = {
  duration: 3000,
  transitionDuration: 600,
  infinite: true,
  indicators: false,
  arrows: true,
  pauseOnHover: true,
  onChange: (oldIndex, newIndex) => {
    console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  },
};

const LandingPage = () => {
  // const [tabNumber, setTabNumber] = useState(1);

  return (
    <React.Fragment>
      {/* Book now banner */}
      <section>
        <div className="slide-container">
          <Slide {...properties}>
            <div className="each-slide">
              <div
                className="bg-cover"
                style={{ backgroundImage: `url(${slideImages[0]})` }}
              >
                <span>
                  <img
                    src="/venlogo.png"
                    className="img-fluid  d-block mx-auto"
                    style={{ width: 140, paddingTop: "160px" }}
                    alt="vennington_logo"
                  />
                  <p
                    className="text-center h2 mt-3 font-weight-bold"
                    style={{ color: "rgb(250, 232, 202)" }}
                  >
                    HOTEL VENNINGTON
                  </p>
                  <br />
                  <DatePicker />
                </span>
              </div>
            </div>

            <div className="each-slide">
              <div
                className="bg-cover"
                style={{ backgroundImage: `url(${slideImages[1]})` }}
              >
                <span>
                  <div className="text-center" style={{ paddingTop: 300 }}>
                    <h2 className="text-white font-weight-bold">Clubs</h2>

                    <a href="/clubs" className="book_link_hover">
                      <p className="btn btn-round shadow">Know More</p>
                    </a>
                  </div>
                </span>
              </div>
            </div>

            <div className="each-slide">
              <div
                className="bg-cover"
                style={{ backgroundImage: `url(${slideImages[2]})` }}
              >
                <span>
                  <div className="text-center" style={{ paddingTop: 300 }}>
                    <h2 className="text-white font-weight-bold">DINING</h2>

                    <a href="/banquet" className="book_link_hover">
                      <p className="btn btn-round shadow">View More</p>
                    </a>
                  </div>
                </span>
              </div>
            </div>

            <div className="each-slide">
              <div
                className="bg-cover"
                style={{ backgroundImage: `url(${slideImages[3]})` }}
              >
                <span>
                  <div className="text-center" style={{ paddingTop: 300 }}>
                    <h2 className="text-Dark font-weight-bold">
                      MEETING & CONFERENCE
                    </h2>
                    <a href="/dinning" className="book_link_hover">
                      <p className="btn btn-round shadow">View More</p>
                    </a>
                  </div>
                </span>
              </div>
            </div>

            <div className="each-slide">
              <div
                className="bg-cover"
                style={{ backgroundImage: `url(${slideImages[4]})` }}
              >
                <span>
                  <div className="text-center" style={{ paddingTop: 300 }}>
                    <h2 className="text-white font-weight-bold">RECEPTION</h2>
                    <a href="/dinning" className="book_link_hover">
                      <p className="btn btn-round shadow">View More</p>
                    </a>
                  </div>
                </span>
              </div>
            </div>
          </Slide>
        </div>
      </section>

      <About />
      <Rooms />

      <Facilities />
      {/* <Cta /> */}
      <Services />
      {/* <Testimonials /> */}
      {/* Room section end */}
    </React.Fragment>
  );
};

export default LandingPage;
