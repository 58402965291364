import React, { Component } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import RoomImage from "./RoomImage";

export class HorizontalScroll extends Component {
  render() {
    const { lists, room } = this.props;
    return (
      <div>
        <ScrollMenu
          data={Menu(lists, "item1", room)}
          onSelect={() => {
            console.log("onslect");
          }}
          wheel={false}
        />
      </div>
    );
  }
}

const MenuItem = ({ image, room }) => {
  return <RoomImage src={image} room={room} />;
};

export const Menu = (list, selected, room) =>
  list.map((el, i) => {
    return <MenuItem key={i} image={el} room={room} />;
  });

// const Arrow = ({ text, className }) => {
//   return <div className={className}>{text}</div>;
// };

// const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
// const ArrowRight = Arrow({ text: ">", className: "arrow-next" });

export default HorizontalScroll;
