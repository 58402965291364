import React from "react";
import RoomDetails from "../components/RoomDetails";

const Allrooms = () => (
  <React.Fragment>
    <div className="container" style={{ paddingTop: 120 }}>
      <RoomDetails />
    </div>
  </React.Fragment>
);

export default Allrooms;
