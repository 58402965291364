/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { tada } from "react-animations";
import Radium, { StyleRoot } from "radium";

const styles = {
  tada: {
    animation: "x 1s infinite",
    animationName: Radium.keyframes(tada, "tada"),
  },
};

const NavBar = () => (
  <StyleRoot>
    <nav className="navbar bg-white navbar-expand-md navbar-light fixed-top">
      <div className="container">
        <a href="/" className="navbar-brand">
          <img
            alt="vennington logo"
            style={{ width: 50, height: 50 }}
            src="https://res.cloudinary.com/relive-cloud/image/upload/v1590935303/vennington/venningtonlogo.png"
          />
        </a>
        <button
          type="button"
          className="navbar-toggler bg-light"
          data-toggle="collapse"
          data-target="#nav"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse justify-content-between"
          id="nav"
        >
          <ul className="navbar-nav ">
            <li className="nav-item">
              <a
                className="nav-link   text-uppercase font-weight-bold px-3"
                href="/"
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link  text-uppercase font-weight-bold px-3"
                href="/#rooms"
              >
                Accommodation
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link  text-uppercase font-weight-bold px-3"
                href="/clubs"
              >
                Clubs
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link  text-uppercase font-weight-bold px-3"
                href="/dinning"
              >
                Dining
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link  text-uppercase font-weight-bold px-3"
                href="/banquet"
              >
                Meeting & conference
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link  text-uppercase font-weight-bold px-3"
                href="/contact"
              >
                Contact
              </a>
            </li>
          </ul>
          <a href="tel:077143 33333" className="headtext" style={styles.tada}>
            <i className="fa fa-phone " style={{ fontSize: 25 }} />
          </a>
        </div>
      </div>
    </nav>
  </StyleRoot>
);

export default NavBar;
