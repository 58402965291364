import React, { useEffect } from "react";
import { ToastsStore } from "react-toasts";
import Rest from "../utils/Rest";
import moment from "moment";
import { withRouter } from "react-router-dom";

const validName = (n) => {
  var filter = /^[a-zA-Z ]*$/;
  console.log(String(n).search(filter) !== -1);
  return String(n).search(filter) !== -1;
};

// const validMobile = (m) => {
//   var filter = /^[0]?[123456789]\d{4,14}$/;
//   console.log(String(m).search(filter) !== -1);
//   return String(m).search(filter) !== -1;
// };

const CheckOutButton = (props) => {
  const { history } = props;
  const openPayModal = () => {
    const {
      amount,
      mobile,
      email,
      name,
      room,
      adult,
      child,
      extraMattress,
      checkIn,
      checkOut,
    } = props;
    if (name === "") {
      ToastsStore.error("Booking name is required");
      return;
    }
    if (name.length < 4) {
      ToastsStore.error("Booking name is too short");
      return;
    }
    if (name.length > 60) {
      ToastsStore.error("Booking name is too large");
      return;
    }
    if (!validName(name)) {
      ToastsStore.error("Booking name is invalid");
      return;
    }
    if (mobile === "") {
      ToastsStore.error("Mobile number is required");
      return;
    }
    if (mobile.length < 5) {
      ToastsStore.error("Mobile number is too short");
      return;
    }
    if (mobile.length > 15) {
      ToastsStore.error("Mobile number is too large");
      return;
    }
    const options = {
      // key: "rzp_test_VnzEMglb7iEt2X", // Test API Key
      key: "rzp_live_1wpuIxh5HfgjVz", // Live API Key
      amount: parseInt(amount) * 100, //  = INR 1
      name: "Hotel Vennington",
      description: "Amount Payable",
      image:
        "https://res.cloudinary.com/relive-cloud/image/upload/v1590935303/vennington/venningtonlogo.png",
      handler: async (response) => {
        try {
          const roomId = room._id;
          const freshCheckIn = moment(checkIn, "DD/MM/YYYY").format(
            "YYYY/MM/DD"
          );
          const freshCheckOut = moment(checkOut, "DD/MM/YYYY").format(
            "YYYY/MM/DD"
          );
          const result = await Rest.post("/booking", {
            roomId,
            name,
            mobile,
            email,
            checkIn: freshCheckIn,
            checkOut: freshCheckOut,
            adult: parseInt(adult),
            child: parseInt(child),
            extraMattress,
            razorPayId: response.razorpay_payment_id,
          });
          ToastsStore.success(result.data.message);
          history.push(`/booking/${result.data.payload._id}`);
        } catch (error) {
          console.log(error.response);
          ToastsStore.error("Booking Failed ");
        }
      },
      prefill: {
        name,
        contact: `91${mobile}`,
        email: email,
      },
      notes: {
        address: "some address",
      },
      theme: {
        color: "#f89639",
        hide_topbar: false,
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <button
      onClick={openPayModal}
      className="btn  btn-primary btn-lg btn-block"
      type="submit"
      style={{ color: "#fff", border: "1px solid #fff", background: "#a02b2d" }}
    >
      Continue to checkout
    </button>
  );
};

export default withRouter(CheckOutButton);
