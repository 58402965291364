import React from 'react'
import CassanovaMenu from '../components/CassanovaMenu'

const CassanovaMenuPage = () => {
    return (
        <div>
            <CassanovaMenu/>
        </div>
    )
}

export default CassanovaMenuPage
