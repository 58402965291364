/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Facilities = () => (
  <React.Fragment>
    <div className="bg-light pt-5 shadow-sm" id="facilities">
      <div className="container">
        <div className="row  pb-5">
          <div className="col-md-6">
            <h1 className=" text-center headtext">Facilities</h1>
            <hr className="bordermin" />
            <p className="text-center text-secondary lead">
              Lorem ipsum is placeholder text commonly used in the graphic,
              print, and publishing industries for previewing layouts and visual
              mockups.
            </p>
            <a href="/facilities" className="link_hover">
              <button className="btn btn-outline-maroon btn-lg rounded mx-auto d-block">
                Learn more
              </button>
            </a>
          </div>
          <div className="col-12 col-md-6 pt-5 pt-md-0">
            <div className="row justify-content-left">
              <div className="col-3 m-auto text-center">
                <img
                  alt="image"
                  className="fdb-icon facilitiesicon"
                  src="/gym.png"
                />
                <p className="text-maroon">
                  <a href="/facilities" className="link_hover">
                    Fitness Center
                    <br />
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </p>
              </div>
              <div className="col-3 m-auto text-center">
                <img
                  alt="image"
                  className="fdb-icon facilitiesicon"
                  src="/doctor.png"
                />
                <p className="text-maroon">
                  <a href="/facilities" className="link_hover">
                    Doctor on Call
                    <br />
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </p>
              </div>
              <div className="col-3 m-auto text-center">
                <img
                  alt="image"
                  className="fdb-icon facilitiesicon"
                  src="/wifi.png"
                />
                <p className="text-maroon">
                  <a href="/facilities" className="link_hover">
                    Wi-Fi
                    <br />
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </p>
              </div>
            </div>
            <div className="row justify-content-left mt-4 mt-xl-5">
              <div className="col-3 m-auto text-center">
                <img
                  alt="image"
                  className="fdb-icon facilitiesicon"
                  src="/pool.png"
                />
                <p className="text-maroon">
                  <a href="/facilities" className="link_hover">
                    Swimming Pool
                    <br />
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </p>
              </div>
              <div className="col-3 m-auto text-center">
                <img
                  alt="image"
                  className="fdb-icon facilitiesicon"
                  src="/car-parking.png"
                />
                <p className="text-maroon">
                  <a href="/facilities" className="link_hover">
                    Valley Parking
                    <br />
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default Facilities;
