import React from "react";

const Banquet = () => (
  <React.Fragment>
    <div className="container">
      <p className="text-center h1 headtext" style={{ marginTop: 140 }}>
        <u>Banquet</u>
      </p>
      <div className="row">
        <div className="col-md-6">
          <p className="lead text-dark text-secondary">
            Two Banquet halls up to 500 gatherings a right place to make your
            Events Parties, Seminars Marriage & Reception , a memorabale one.
          </p>
        </div>
        <div className="col-md-6 text-right">
          <button
            type="button"
            class="btn btn-lg btn-outline-danger inquirybtn textwrap font-weight-bold mr-4"
          >
            <a href="tel:077143 33333">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </a>
          </button>
          {/* 
          <button
            type="button"
            class="btn btn-lg btn-outline-danger inquirybtn textwrap font-weight-bold"
          >
            <a href="/contact">Inquiry Now</a>
          </button> */}
        </div>
      </div>
      <div className="row" style={{ marginTop: 30 }}>
        <div className="col-md-4 ">
          <img
            src="/banquet/banquet1.jpg"
            className="img-fluid img-thumbnail shadow clubsimg"
            alt="clubs"
          />
        </div>
        <div className="col-md-4">
          <img
            src="/banquet/banquet2.jpg"
            className="img-fluid img-thumbnail shadow clubsimg"
            alt="clubs"
          />
        </div>
        <div className="col-md-4">
          <img
            src="/banquet/banquet3.jpg"
            className="img-fluid img-thumbnail shadow clubsimg"
            alt="clubs"
          />
        </div>
      </div>
      {/* <div className="row" style={{ marginTop: 30 }}>
        <div className="col-md-4 ">
          <img
            src="https://images.pexels.com/photos/164745/pexels-photo-164745.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="img-fluid img-thumbnail shadow clubsimg"
            alt="clubs"
          />
        </div>
        <div className="col-md-4">
          <img
            src="https://images.pexels.com/photos/4784/alcohol-bar-party-cocktail.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="img-fluid img-thumbnail shadow clubsimg"
            alt="clubs"
          />
        </div>
        <div className="col-md-4">
          <img
            src="https://images.pexels.com/photos/382297/pexels-photo-382297.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="img-fluid img-thumbnail shadow clubsimg"
            alt="clubs"
          />
        </div>
      </div> */}
    </div>
    <br />
    <br />
  </React.Fragment>
);

export default Banquet;
