/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const ServicesPage = () => (
  <React.Fragment>
    <section className="fdb-block pb-5">
      <div className="container">
        <div className="row justify-content-center pb-5">
          <div className="col-12 text-center" style={{ marginTop: 130 }}>
            <h1 className=" font-weight-bold headtext">Services</h1>
            <hr className="bordermin" />
          </div>
        </div>
        <div className="row text-left align-items-center pt-5 pb-md-5">
          <div className="col-4 col-md-5">
            <img
              alt="image"
              className="img-fluid facilitiespageicon"
              src="/service/quzeen.jpg"
            />
          </div>
          <div className="col-12 col-md-5 m-md-auto">
            <h2 id="qzeen">
              <a href="/dinning">
                {" "}
                <strong className="headtext">Q - ZEEN</strong>
              </a>
            </h2>
            <p className="lead">
              A Multicuisine Restaurant.with Live kitchen offers a wide range of
              Indian and International Cusine .
            </p>
          </div>
        </div>
        <div className="row text-left align-items-center pt-5 pb-md-5">
          <div className="col-4 col-md-5 m-md-auto order-md-5">
            <img
              alt="image"
              className="img-fluid facilitiespageicon"
              src="/service/tafree.png"
            />
          </div>
          <div className="col-12 col-md-5">
            <h2 id="tafree">
              <a href="/dinning">
                <strong className="headtext">Tafree</strong>
              </a>
            </h2>
            <p className="lead">
              A Roof top open Restaurant & Bar with live kitchen & Swimming
              pool- a most happening place where you enjoy varieties of Kebabs
              and Chef Special dishes on live ,Sitting on top embarace a
              paranomic view of Raipur city.
            </p>
            <p>
              <b>Tafree dimension:</b>&nbsp; Width:29.6, Length:63, Sqft: 1864.8
            </p>
          </div>
        </div>
        <div className="row text-left align-items-center pt-5">
          <div className="col-4 col-md-5">
            <img
              alt="image"
              className="img-fluid facilitiespageicon"
              src="/service/petals.jpg"
            />
          </div>
          <div className="col-12 col-md-5 m-md-auto">
            <h2 id="banquet">
              <a href="/banquet">
                <strong className="headtext">PETALS & CHERISH</strong>
              </a>
            </h2>
            <p className="lead">
              Disco Theque.-Combination of a rustic & modern ambience with Loud
              music where our Youmgs break their Legs & moods.
            </p>
            <p>
              <b>Petals hall dimension:</b> width: 75.6 , length:58.3, sqft:
              4407.48
            </p>
            <p>
              <b>Cherish hall dimension:</b> width: 29.5 , length:58, sqft: 1711
            </p>
          </div>
        </div>

        <div className="row text-left align-items-center pt-5 pb-md-5">
          <div className="col-4 col-md-5 m-md-auto order-md-5">
            <img
              alt="image"
              className="img-fluid facilitiespageicon"
              src="/service/deal.jpg"
            />
          </div>
          <div className="col-12 col-md-5">
            <h2 id="deal">
              <a href="/banquet">
                <strong className="headtext">THE DEAL</strong>
              </a>
            </h2>
            <p className="lead">
              A Roof top open Restaurant & Bar with live kitchen & Swimming
              pool- a most happening place where you enjoy varieties of Kebabs
              and Chef Special dishes on live ,Sitting on top embarace a
              paranomic view of Raipur city.
            </p>
            <p>
              <b>The Deal (Boardroom dimension):</b> width: 19.1, Length:25,
              sqft: 477.5{" "}
            </p>
          </div>
        </div>

        <div className="row text-left align-items-center pt-5">
          <div className="col-4 col-md-5">
            <img
              alt="image"
              className="img-fluid facilitiespageicon"
              src="https://cdn.pixabay.com/photo/2015/05/15/14/50/concert-768722_960_720.jpg"
            />
          </div>
          <div className="col-12 col-md-5 m-md-auto">
            <h2 id="clubs">
              <a href="/clubs">
                <strong className="headtext">Club Casanova</strong>
              </a>
            </h2>
            <p className="lead">
              Disco Theque.-Combination of a rustic & modern ambience with Loud
              music where our Youmgs break their Legs & moods.
            </p>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default ServicesPage;
