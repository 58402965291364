import React from "react";

const AboutPage = () => (
  <React.Fragment>
    <div className="container" style={myStyles}>
      <h1 className="headtext text-center">About</h1>
      <hr className="bordermin" />

      <p className="lead text-secondary">
        Conceptualizing, theme & motto based on (3 C) Comfort, Cuisine and
        Culture; Hotel Vennington Court is established in December 2016.
        Experience Luxury hospitality with us, our hotel is located at the
        finest and convenient place of Jail Road Raipur in Capital City.
      </p>
      <p className="lead text-secondary">
        A walkable distance from Railway station, surrounded with bustling
        Shopping & Entertainment center, Hospital, Government & Corporate
        offices .and just a 30 mints drives to Airport. Hotel Vennington Court
        with its Signature architecture, in no time established itself as the
        market leader in the city. The hotel is synonymous with Style, Class and
        Services which are of a truly international standard.
      </p>

      <p className="lead text-secondary">
        Hotel Vennington Court is truly a hotel which showcases the best of
        Contemporary interiors, quality services and cater to all of your
        business needs.
      </p>

      <p className="lead text-secondary">
        The other attractions are Q - zeen - a Multicuisine Restaurant with live
        kitchen, Tafree - a Roof top open Restaurant & Bar with live kitchen &
        swimming pool, Club Casanova- Disco theque, The Petel & Chrish - two
        banquet halls up to 500 gatherings and above, the Deal - Hi tech Board
        room to meet all essential needs of corporate body. Last but not the
        least is a Gym to shape your body and tune your mind.
      </p>

      <p className="lead text-secondary">
        We have got ambitious growth plans and would always strive to be market
        leaders in the region they operate.
      </p>
    </div>
  </React.Fragment>
);

const myStyles = {
  marginTop: 100,
};
export default AboutPage;
