/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Rest from "../utils/Rest";
import Loader from "react-loader";
// import calculatePrice from "../utils/calculatePrice";

const BookingDetailsPage = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [booking, setBooking] = useState(null);
  const { bookingId } = props.match.params;
  useEffect(() => {
    const fetchData = async () => {
      const result = await Rest.get(`/booking/${bookingId}`);
      setBooking(result.data.payload);
      setLoading(true);
    };
    fetchData();
  }, [bookingId]);
  if (booking === null) {
    return <Loader loaded={isLoading}></Loader>;
  }

  // const { price, isOffer, offer, offerType, offerDescription } = calculatePrice(booking.room, booking.adult, booking.child);

  return (
    <React.Fragment>
      <div className="container">
        <section style={{ marginTop: 100, height: "800px" }}>
          <div className="container">
            <div
              className="align-items-center p-3"
              style={{ border: "3px solid #e3e3e3", borderStyle: "dashed" }}
            >
              <div>
                <img
                  alt="image"
                  className="img-fluid rounded-0 mx-auto d-block "
                  src="https://res.cloudinary.com/relive-cloud/image/upload/v1591448825/vennington/success.png"
                  style={{ width: 100 }}
                />
                <div className="fdb-box fdb-touch mt-3">
                  <div className="row justify-content-center">
                    <div className="col-12 col-xl-8 text-center">
                      <p className="booktext">Room has been booked !</p>
                      <p className="text-center">
                        Booking Number: <b>{booking.bookingId}</b>
                      </p>
                    </div>
                    <div
                      className="shadow-sm d-block mx-auto lead p-3"
                      style={{ borderTop: "3px solid  #F89620" }}
                    >
                      <div className="d-flex justify-content-between flex-wrap mob-text">
                        <div>
                          <p className="h4">{booking.name}</p>
                          <p style={{ fontSize: 16 }}>
                            {" "}
                            <b className="font-weight-bold"> Mob:</b>{" "}
                            {booking.mobile}
                          </p>
                          <span>
                            <p style={{ fontSize: 14 }}>
                              {" "}
                              <img
                                src="/date.png"
                                alt="date"
                                style={{ width: 16 }}
                              />{" "}
                              <strong>05/05/2020 to 10/05/2020</strong>
                            </p>
                          </span>{" "}
                        </div>
                        <div>
                          <p className="text-secondary h6">
                            Amount <br />
                            <b className="h3 text-dark">Rs.{booking.price}</b>
                          </p>
                          {/* <p className="text-secondary text-right" style={{ fontSize: 12, marginTop: "-10px" }}>
                            ( 30% Off )
                          </p> */}
                          <p />
                        </div>
                      </div>

                      <div style={{ fontSize: 16 }} className="w-100">
                        <span className="">
                          <a
                            href="https://g.page/Vennington?share"
                            className="text-dark"
                          >
                            <img
                              src="/vennmap.png"
                              className="w-100 mx-auto d-block shadow-sm "
                              alt="map"
                            />
                          </a>
                          <p
                            style={{ fontSize: 12 }}
                            className="mt-1 text-secondary text-center"
                          >
                            Jail Rd, Sai Vihar Colony, Raipur, Chhattisgarh
                            492001{" "}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <p
                  className="text-center text-secondary mt-2"
                  style={{ fontSize: 12 }}
                >
                  Please save receipt for seamless check in experience at Hotel
                  Vennington.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BookingDetailsPage);
