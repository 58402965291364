import React, { useState, useEffect } from "react";
import Rest from "../utils/Rest";
import Loader from "react-loader";
import Product from "./Product";

const RoomDetails = () => {
  const [isLoading, setLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const result = await Rest.get("/room");
      setRooms(result.data.payload);
      setLoading(true);
    };
    fetchData();
  }, []);
  return (
    <div>
      <Loader loaded={isLoading}>
        {rooms.map((room, i) => {
          return <Product key={i} room={room} />;
        })}
      </Loader>
    </div>
  );
};

export default RoomDetails;
