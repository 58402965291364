import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";

const RoomImage = ({ src, room, location }) => {
  const [width, setWidth] = useState(window.screen.width);
  const displayWindowSize = () => {
    setWidth(document.documentElement.clientWidth);
  };
  window.addEventListener("resize", displayWindowSize);
  return (
    <div style={{ margin: 5 }}>
      <Link to={`/room/${room._id}${location.search}`}>
        <img
          src={src}
          alt="roomimage"
          className="  roomimgwidth img-fluid"
          style={{ width: width > 720 ? 200 : 130, height: width > 720 ? 200 : 150, verticalAlign: "bottom", borderRadius: 15 }}
        />
      </Link>
    </div>
  );
};

export default withRouter(RoomImage);
