/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

const Footer = () => {
  return (
    <footer class="fdb-block footer-large bg-light pt-5 pb-5">
      <div class="container">
        <div class="row align-items-top text-center">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 text-sm-left">
            <img
              alt="vennington logo"
              style={{ width: 100, height: 100 }}
              src="https://res.cloudinary.com/relive-cloud/image/upload/v1590935303/vennington/venningtonlogo.png"
            />
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-5 mt-sm-0 text-sm-left">
            <h3 className="headtext">
              <strong>Links</strong>
            </h3>
            <nav class="nav flex-column">
              <a class="nav-link link_hover" href="/clubs">
                Clubs
              </a>
              <a class="nav-link link_hover" href="/banquet">
                Banquet
              </a>
              <a class="nav-link link_hover" href="/contact">
                Contact
              </a>
            </nav>
          </div>

          <div class="col-12 col-md-4 col-lg-3 text-md-left mt-5 mt-md-0">
            <h3 className="headtext">
              <strong>Our location</strong>
            </h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d20436.34025156219!2d81.6412074889727!3d21.25055987354821!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6c6d235b7ba9fe03!2sHotel%20Vennington%20Court!5e0!3m2!1sen!2sin!4v1591707927020!5m2!1sen!2sin"
              width="100%"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>

          <div class="col-12 col-lg-2 ml-auto text-lg-left mt-4 mt-lg-0">
            <h3 className="headtext">
              <strong>Follow Us</strong>
            </h3>
            <p class="lead">
              <a
                href="https://m.facebook.com/VCRAIPUR/"
                target="_blank"
                rel="noopener noreferrer"
                class="mx-2"
              >
                <i class="fa fa-facebook-square" aria-hidden="true"></i>
              </a>
              <a
                href="https://www.instagram.com/hotelvennington/"
                target="_blank"
                rel="noopener noreferrer"
                class="mx-2"
              >
                <i class="fa fa-instagram" aria-hidden="true"></i>
              </a>
              <a
                href="https://twitter.com/court_hotel"
                target="_blank"
                rel="noopener noreferrer"
                class="mx-2"
              >
                <i class="fa fa-twitter" aria-hidden="true"></i>
              </a>
            </p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col text-center">
            © 2020 Hotel vennington. All Rights Reserved
          </div>
        </div>
      </div>
    </footer>

    // <footer
    //   className="fdb-block footer-small p-3 bg-light "
    //   style={{
    //     borderTop: "1px solid #e3e3e3",
    //   }}
    // >
    //   <div className="container">
    //     <div className="row text-center align-items-center">
    //       <div className="col-12 col-lg-2 text-lg-left">
    //         <img
    //           alt="vennington-logo"
    //           src="https://res.cloudinary.com/relive-cloud/image/upload/v1590935303/vennington/venningtonlogo.png"
    //           height={40}
    //         />
    //       </div>
    //       <div className="col mt-4 mt-lg-0 text-center">
    //         <div className="col text-center text-secondary">
    //           © 2020 Hotel Vennington. All Rights Reserved
    //         </div>
    //       </div>
    //       <div className="col-12 col-lg-2 mt-4 mt-lg-0 text-lg-right ">
    //         <a href="/facebook" className="mx-2">
    //           <i
    //             class="fa fa-facebook-square text-orange"
    //             aria-hidden="true"
    //           ></i>
    //         </a>
    //         <a href="/instagram" className="mx-2">
    //           <i class="fa fa-instagram text-orange" aria-hidden="true"></i>
    //         </a>
    //         <a href="/twitter" className="mx-2">
    //           <i class="fa fa-twitter text-orange" aria-hidden="true"></i>
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    // </footer>
  );
};

export default Footer;
